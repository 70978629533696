


function PageNotFound () {



    return(
        <div>
            <h1>Page Not Found</h1>
        </div>
    )
}

export default PageNotFound;